import { GetEcuFleetConditionDocument } from '../generated/graphql'
import { getQueryVariables } from '../pages/Statistics/getQueryVariables'

import {
  DLTYPE_LATEST_MEASUREMENTS,
  DLTYPE_MEASUREMENTS_SUMMARY,
} from '../lib/measurement'
import apolloClient from '../lib/apollo-client'
import { findWorstComponentCondition } from './iot-client-helpers'
import {resolveTimeframe} from '@kc/alphabrands-component-library'

const formatConditionLastestMeasurements = (fleetConditionStatus) => {
  if (fleetConditionStatus == null || fleetConditionStatus.length === 0) {
    return null
  }
  const returnLatestFleetCondition = fleetConditionStatus.flatMap(
    (fleetCondition) => {
      return Object.keys(fleetCondition)
        .filter((kpi) => kpi !== 'cn')
        .map((kpi) => ({
          cn: fleetCondition[kpi].cn,
          v: fleetCondition[kpi].latestPercentage,
          vid: fleetCondition[kpi].vid,
        }))
    }
  )

  return returnLatestFleetCondition
}

export const getDataFromIotApi = async (asset, dltype) => {
  const timeframe = resolveTimeframe()
  const { variables } = getQueryVariables(asset, timeframe)
  
  try {
    const res = await apolloClient.query({
      query: GetEcuFleetConditionDocument,
      variables,
    })
    let returnData

    switch (dltype) {
      case DLTYPE_MEASUREMENTS_SUMMARY:
        returnData = findWorstComponentCondition(
          res.data?.measurementStatistics?.crane.fleet
        )
        break

      case DLTYPE_LATEST_MEASUREMENTS:
        returnData = formatConditionLastestMeasurements(
          res.data?.measurementStatistics?.crane.fleet
        )
        break

      default:
        returnData = null
        break
    }

    return {
      data: returnData,
      status: 200,
    }
  } catch (error) {
    return {
      data: null,
      status: 400,
    }
  }
}
